import { GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import MuiToolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

import ExportButtons from './ExportButtons';
import { TableName } from './TableName';

export default function Toolbar(): JSX.Element {
  return (
    <GridToolbarContainer>
      <MuiToolbar sx={{ width: '100%' }}>
        <TableName />
        <Box display='flex' gap={3}>
          <GridToolbarFilterButton sx={{ fontSize: 14 }} />
          <ExportButtons />
        </Box>
      </MuiToolbar>
    </GridToolbarContainer>
  );
}
