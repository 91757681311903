import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

type ServerError = { status: number; message: string };

const fetchLastInn = createAsyncThunk<
  string[],
  void,
  { extra: AxiosInstance; rejectValue: string }
>('@@contragents/fetch-last-inn', async (_, { extra: api, rejectWithValue }) => {
  try {
    const response: AxiosResponse<string[]> = await api.get('/last-results/last-inn');
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError<ServerError>;
      return rejectWithValue(error.response ? error.response.data.message : 'Что-то пошло не так');
    } else {
      return rejectWithValue('Что-то пошло не так');
    }
  }
});
export default fetchLastInn;
