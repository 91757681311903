import { useState, MouseEvent } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { selectContragents } from 'store/selectors';
import Button from '@mui/material/Button';
import SyncIcon from '@mui/icons-material/Sync';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import Box from '@mui/material/Box';
import fetchPrevResult from 'store/slices/contragent/thunk/fetchPrevResult';
import fetchBeforeResult from 'store/slices/contragent/thunk/fetchBeforeResult';
import Stack from '@mui/material/Stack';
import Table from 'components/Table';
import fetchRealResult from 'store/slices/contragent/thunk/fetchRealResult';

interface IContragentsDataProps {
  handleBackToInput: () => void;
}

export default function ContragentsData({ handleBackToInput }: IContragentsDataProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { status, data } = useAppSelector(selectContragents);
  const [activeButton, setActiveButton] = useState('first');

  const clickedButtonHandler = (e: MouseEvent<HTMLButtonElement>): void => {
    const { name } = e.target as HTMLButtonElement;

    setActiveButton(name);

    if (name === 'first') {
      dispatch(fetchRealResult());
    } else if (name === 'second') {
      dispatch(fetchPrevResult());
    } else {
      dispatch(fetchBeforeResult());
    }
  };

  return (
    <Stack gap={2}>
      <Box display='flex' gap={1}>
        <Button
          name='first'
          color={activeButton === 'first' ? 'warning' : 'primary'}
          variant={activeButton === 'first' ? 'contained' : 'text'}
          onClick={clickedButtonHandler}
        >
          Настоящие данные
        </Button>
        <Button
          name='second'
          color={activeButton === 'second' ? 'warning' : 'primary'}
          variant={activeButton === 'second' ? 'contained' : 'text'}
          onClick={clickedButtonHandler}
        >
          Прошлые данные
        </Button>
        <Button
          name='last'
          color={activeButton === 'last' ? 'warning' : 'primary'}
          variant={activeButton === 'last' ? 'contained' : 'text'}
          onClick={clickedButtonHandler}
        >
          Позапрошлые данные
        </Button>
      </Box>
      <Table data={data.value ? data.value : []} status={status} />
      <Box display='flex' gap={2} mb={2}>
        <Button onClick={handleBackToInput} color='info' startIcon={<ReplyAllIcon />}>
          Перейти к вводу ИНН
        </Button>
        <Button onClick={handleBackToInput} color='info' startIcon={<SyncIcon />}>
          Повторить ИНН
        </Button>
      </Box>
    </Stack>
  );
}
