import { memo, useLayoutEffect } from 'react';
import ChipInput from 'components/ChipInput';
import useInn from 'hooks/useInn';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Socket } from 'socket.io-client';
import { useAppDispatch } from 'store';
import fetchLastInn from 'store/slices/inn/thunk/fetchLastInn';

interface ISearchContragentsProps {
  socket: Socket;
  isConnect: boolean;
}

function SearchContragents({ socket, isConnect }: ISearchContragentsProps): JSX.Element {
  const { addInns, removeInns, inns, resetInn } = useInn();
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatch(fetchLastInn());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchContragent = (): void => {
    socket.emit('events', { type: 'run', innDto: inns });
  };

  return (
    <>
      <ChipInput add={addInns} data={inns} remove={removeInns} />
      <Box display='flex' gap={2} mb={2}>
        <Button
          variant='contained'
          sx={{ mt: 1, mr: 1 }}
          onClick={fetchContragent}
          disabled={!inns.length || !isConnect}
        >
          Найти
        </Button>
        <Button
          variant='outlined'
          color='error'
          sx={{ mt: 1, mr: 1 }}
          onClick={resetInn}
          disabled={!inns.length}
        >
          Очистить
        </Button>
      </Box>
    </>
  );
}
export default memo(SearchContragents);
